@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,800;0,900;1,600;1,700&display=swap');

body {
  font-family: 'Poppins', sans-serif !important;
  background: #ebecee;
}

button,
button:hover,
button:focus,
button:active {
  outline: 0 !important;
  box-shadow: none !important;
}

playlist-module input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.txt-error {
  border-color: red !important;
}

.error-label {
  font-size: 14px;
  color: red;
}

.logo-container {
  text-align: center;
  margin-bottom: 30px;
}

.btn-black {
  background: #000 !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.form-title {
  font-size: 28px;
  font-weight: 700;
}

.forgot-link,
.forgot-link:hover {
  font-size: 13px !important;
  font-weight: 700 !important;
  color: #8c9195 !important;
  text-decoration: none !important;
}

.btn-grp {
  text-align: right;
}

.btn-grp .btn {
  font-size: 12px;
  padding: 8px 10px;
  margin-left: 4px;
  border-radius: 0;
  color: #fff;
  text-transform: uppercase;
}

.btn-grp .btn:first-child {
  margin-left: 0;
}

.btn.btn-purple {
  background: #a349a4;
  border-color: #a349a4;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
}
.btn.btn-purple:hover, .btn.btn-purple:focus, .btn.btn-purple:active {
  background: #a349a4 !important;
  border-color: #a349a4 !important;
  color: #fff !important;
}

.btn.btn-yellow {
  background: #e6ba0c;
  border-color: #e6ba0c;
  /* background: linear-gradient(228.59deg, #e5895a 0%, #e46296 100%);
  border: 1px solid #e5895a; */
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
}

.btn.btn-black {
  background: #000;
  border-color: #000;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
}

.m-0 {
  margin: 0;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.btn {
  font-weight: 600 !important;
  border-radius: 2px !important;
}

.font-18 {
  font-size: 18px;
}

.custon-select-control .css-yk16xz-control {
  border-radius: 0;
  border-color: #e8e8e8;
  font-size: 15px;
  cursor: pointer;
}

.custon-select-control .css-1okebmr-indicatorSeparator {
  display: none;
}

.custon-select-control .css-1pahdxg-control,
.custon-select-control .css-1pahdxg-control:hover,
.custon-select-control .css-1pahdxg-control:focus {
  border-radius: 0;
  font-size: 15px;
  border-color: #e8e8e8;
  border-width: 1;
  box-shadow: none;
}

.custon-select-control .css-2613qy-menu {
  font-size: 14px;
}

.custon-select-control .css-yk16xz-control:hover {
  border-color: #e8e8e8;
}

.btn-filter .btn-secondary {
  color: #5c6873;
  background-color: transparent;
  border-color: #e8e8e8;
  border-radius: 0;
  min-height: 38px;
  background: transparent !important;
  border-color: #e8e8e8 !important;
}

.form-control.custom-input {
  border-radius: 0;
  font-size: 15px;
  border-color: #e8e8e8;
  min-height: 38px;
  border-radius: 0;
}

textarea.form-control {
  resize: none;
}

.form-control:hover,
.form-control:focus,
.btn:hover,
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.font-20 {
  font-size: 20px;
}

.custom-file {
  margin-top: 15px;
  text-align: center;
  height: auto !important;
}

.custom-file input[type="file"] {
  display: none;
}

.custom-file label {
  background: #000;
  color: #fff;
  display: inline-block;
  min-width: 160px;
  padding: 8px 15px;
  text-transform: uppercase;
}

.custom-file label.remove {
  width: 40px;
  min-width: 40px;
  background: #ff8d00;
  padding: 10px 0 6px;
  vertical-align: top;
  margin: 0;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

.custom-file label.remove span {
  font-size: 18px;
  line-height: 1;
}

.form-subtitle {
  font-size: 20px;
  margin: 0;
}

.invalid-feedback {
  font-size: 14px !important;
}

.sidebar .nav-link.active .nav-icon {
  color: #e6ba0c !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #000 !important;
}

.sidebar .nav-link {
  display: flex !important;
  align-items: center;
}

.sidebar .nav-link .nav-icon {
  font-size: 18px !important;
  color: #b9babb !important;
}

.sidebar-minimized .sidebar .nav-link .nav-icon {
  flex: 0 0 50px;
}

.sidebar .nav-link:hover {
  color: #fff;
  background: #222 !important;
}

.rdt_Table {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 25px;
}

.rdt_TableHeader {
  display: none !important;
}

.app-header .nav-item .nav-link>.img-avatar,
.app-header .nav-item .avatar.nav-link>img {
  width: 35px;
  height: 35px;
  margin: 0 10px;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.action-column {
  display: flex;
  align-items: center;
}

.action-column i {
  cursor: pointer;
}

.action-column i.transform-45 {
  transform: rotate(45deg);
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.rdt_TableHeadRow>div:last-child,
.rdt_TableCell:last-child {
  flex: 0 0 140px;
  /* justify-content: center; */
  justify-content: flex-start;
}

.media-card {
  display: flex;
  width: 600px;
  max-width: 100%;
  align-items: center;
  padding: 15px 20px;
  border-radius: 50px;
  margin-bottom: 15px;
  background: #f2f2f2;
}

.media-card .media-name {
  margin: 0 0 0 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

/* AR479-14-11-2019 */
.browse-tablist {
  margin-bottom: 15px;
}

.browse-tablist .nav-tabs a {
  padding: 0.5rem 0.6rem;
}

.category-checkbox {
  display: flex;
  flex-wrap: wrap;
}

.category-checkbox .item {
  border: 1px solid rgba(227, 227, 227, 0.4);
  flex-basis: calc(100% / 6);
  position: relative;
}

.category-checkbox .item .check_none {
  font-size: 0;
  height: 0;
  opacity: 0;
  width: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.category-checkbox .item .check_lbl {
  width: 100%;
  height: 60px;
  color: #b3b3b3;
  padding: 0 24px;
  /* font-size: 16px; */
  font-size: 14px;
  margin-bottom: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-checkbox .item input:checked+.check_lbl {
  background: #ffffff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(26, 24, 24, 0.36);
  box-shadow: 0 1px 4px 0 rgba(26, 24, 24, 0.36);
  color: #2f353a;
}

.category-radio-btn {
  display: flex;
  flex-wrap: wrap;
}

.category-radio-btn .item {
  border: 1px solid rgba(227, 227, 227, 0.4);
  flex-basis: calc(100% / 2);
  position: relative;
}

.category-radio-btn .item .radio_none {
  font-size: 0;
  height: 0;
  opacity: 0;
  width: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.category-radio-btn .item .radio_lbl {
  width: 100%;
  height: 60px;
  color: #b3b3b3;
  padding: 0 24px;
  font-size: 16px;
  margin-bottom: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.category-radio-btn .item .radio_lbl .icon-moon {
  font-size: 30px;
  margin-right: 24px;
}

.category-radio-btn .item .radio_lbl .text {
  flex: 1;
  padding-right: 60px;
  text-align: center;
}

.category-radio-btn .item input:checked+.radio_lbl {
  background: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(26, 24, 24, 0.36);
  box-shadow: 0 1px 4px 0 rgba(26, 24, 24, 0.36);
  color: #2f353a;
}

.category-range .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: #2f353a;
  outline: none;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.category-range .slider:hover {
  opacity: 1;
}

.category-range .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #e6ba0c;
  cursor: pointer;
  border-radius: 50%;
}

.category-range .slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #e6ba0c;
  cursor: pointer;
  border-radius: 50%;
}

/* .react-tags {
  font-size: 15px;
  border: 1px solid #e8e8e8;
  min-height: 38px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
}
.react-tags__search-input {
  display: inline-block;
}
.react-tags__search-input input {
  border: none;
  min-width: 150px !important;
  height: 28px;
  padding: 0;
  outline: 0;
}
.react-tags .react-tags__suggestions {
  position: absolute;
  background: #fff;
  padding: 5px;
  border: 1px solid #ddd;
  min-width: 100px;
  border-radius: 4px;
}
.react-tags .react-tags__suggestions ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.react-tags .react-tags__suggestions ul li {
  padding: 5px 0;
}
.react-tags .react-tags__selected-tag {
  border: 1px solid #ddd;
  background: transparent;
  margin: 0 5px 5px 0;
  padding: 0 10px;
  border-radius: 4px;
} */
.react-tags {
  position: relative;
  /* padding: 6px 0 0 6px; */
  padding: 2px 4px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 3px 3px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 8px 2px;
  /* margin-bottom: 6px; */

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

.custom-notification {
  background-color: #000;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.custom-notification .notification-custom-content {
  padding: 6px 0;
  flex: 1;
}

.custom-notification .notification-custom-icon {
  margin-right: 10px;
}

.custom-notification .notification-close {
  margin-right: auto;
  margin-left: 10px;
}

.notification-item .notification-close:after {
  font-size: 20px !important;
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print),
(prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.toasterContent .notification-item:before {
  content: none;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.toasterContent .notification-item:before {
  content: none;
}

.page-loader {
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  min-width: 150px;
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.deleteError {
  color: red;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

/* AR479-14-11-2019 */

.img-crop-modal .modal-content {
  border-radius: 0;
}

.img-crop-modal .modal-body {
  padding: 40px;
}

.img-crop-modal .button-group {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.custom-check-lbl .MuiTypography-body1 {
  font-size: inherit;
  margin-right: 10px;
}

.loader-pos-center {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}

/* .tbl-pos-center {
  margin-top: 30px;
} */
.form-control.month-select .css-yk16xz-control {
  border: 0;
  border-radius: 0;
  min-height: 38px !important;
}
.device-information .date-range-container{ z-index: inherit !important; }
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item:hover>.nav-link {
    background: #101010;
  }

  .sidebar-minimized .sidebar .sidebar-nav {
    overflow: hidden !important;
  }

  .sidebar-minimized .sidebar .nav>.nav-dropdown.open>.nav-dropdown-items {
    display: block;
    position: relative !important;
    left: 0px !important;
    overflow: hidden;
  }
}

@media (min-width: 576px) {
  .img-crop-modal .modal-dialog {
    max-width: 360px;
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .img-crop-modal .modal-body {
    padding: 10px;
  }
}

.btn {
  font-size: 1.4px;
  text-transform: uppercase;
}

.btn.bold {
  font-weight: 700;
}

.btn.btn-black {
  background: #000;
  border-color: #000;
  color: #fff;
}

.btn.btn-gray {
  border-color: #d9dadd;
}

.drop-outer {
  margin: 0 -1%;
}

.drop-zon {
  display: inline-block;
  background: #f7f6f6;
  border: 2px dashed #d9dadd;
  padding: 0;
  margin: 10px 1% 20px;
  width: 48%;
  text-align: center;
  color: #0d4478;
  cursor: pointer;
}

.drop-zon>div {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-zon div:focus {
  outline: 0;
}

.margin-zero {
  margin: 0 !important;
}

.mr-10 {
  margin-right: 10px;
}

.import-lbl {
  margin: 20px 0 10px;
  font-size: 18px;
}



.


.text-word-wrap {
  word-break: break-all;
}

.disabled-pointer .nav-link:hover {
  cursor: default !important;
}

.sidebar-minimized .sidebar .nav-item:hover>.nav-link,
.sidebar-minimized .sidebar .nav>.nav-dropdown:hover {
  background: #000000 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #000000 !important;
}

.sidebar .nav-item.open>.nav-link .nav-icon {
  /* color: #e6ba0c !important; */
  background: #e46296;
  background: -webkit-gradient(left top,
      right top,
      color-stop(100%, #e46296),
      color-stop(0, #e5895a),
      color-stop(0, #e5895a));
  background: -webkit-linear-gradient(229.67deg, #e5895a, #e46296);
  background: -webkit-linear-gradient(220.33deg, #e5895a, #e46296);
  background: linear-gradient(229.67deg, #e5895a, #e46296);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e46296", endColorstr="#e5895a", GradientType=1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ReactTags__tag {
  display: inline-block;
  background: #f2f2f2;
  border: 1px solid #ddd;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.ReactTags__remove {
  margin-left: 8px;
  font-size: 16px;
  cursor: pointer;
}

.ReactTags__tagInput .ReactTags__tagInputField {
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  width: 100%;
  height: 38px;
  outline: 0;
}

.err-label {
  margin: 5px 0 0;
  color: #dc1313;
  font-size: 14px;
  display: inline-block;
}

.category-slider-container {
  padding: 20px 20px 0;
}

.mt-minus {
  display: block;
  position: relative;
  text-align: right;
}

.pagination {
  justify-content: flex-end;
}

.pagination ul {
  background: #fff;
  border: 1px solid #e6e4e4;
  display: inline-flex;
  list-style: none;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}

.pagination ul li {
  border-right: 1px solid #e6e4e4;
  display: inline-block;
}

.pagination ul li.active {
  background: #2f353a;
}

.pagination ul li.active a,
.pagination ul li.active a:hover {
  color: #fff !important;
  font-weight: 700;
}

.pagination ul li.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.pagination ul li:last-child {
  border-right: none;
}

.pagination ul li a,
.pagination ul li a:hover {
  color: #333 !important;
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  outline: 0;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.filter-result {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.filter-result .title {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.filter-result .result-container {
  margin-left: 10px;
  position: relative;
}

.filter-result .result-container .result {
  background: #ddd;
  color: #000;
  padding: 4px 6px;
  font-size: 13px;
  font-weight: 500;
  margin: 3px 5px;
  border-radius: 3px;
  display: inline-block;
}

.filter-result .result-container .remove-tag {
  margin-left: 5px;
  cursor: pointer;
}

.submittedTracks .custon-select-control {
  width: 100%;
  overflow: visible !important;
}

.lane-title {
  margin: 0;
  font-size: 18px;
}

.darg-card {
  border: 1px solid #999;
  padding: 10px;
  max-width: 310px;
  white-space: normal;
  word-break: break-word;
}

.darg-card .index {
  flex: 0 0 20px;
}

.h-38-no-radius {
  height: 38px !important;
  border-radius: 0 !important;
}

.modal-movie-player .btn-close-audio {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
}

.modal-movie-player .name-size {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 15px;
  padding: 0 15px;
}

.modal-movie-player .name-size .name {
  flex: 1;
  width: calc(100% - 30px);
}

.modal-movie-player .name-size .file-name {
  font-size: 14px;
  margin-bottom: 3px;
  line-height: 1;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal-movie-player .name-size .file-size {
  font-size: 13px;
  color: #999;
  margin-bottom: 0;
}

.modal-movie-player .name-size .download {
  flex: 0 0 30px;
  text-align: right;
}

.modal-movie-player .name-size .download i {
  cursor: pointer;
}

.movie_item_poster {
  position: relative;
}

.movie_item_poster .btn {
  position: absolute;
  right: 0;
  top: 0;
}

.lane-header+div {
  width: 100%;
}

.btn-gradient {
  background: linear-gradient(228.59deg, #e5895a 0%, #e46296 100%);
  color: #fff !important;
  border-radius: 0 !important;
}

.modal-movie-player .movie_item_poster img {
  max-height: 300px;
}

button.close.modal-close {
  text-align: right;
  padding: 10px;
}

/* .custom-disable {
  opacity: 0.2;
  pointer-events: none;
} */
.btn-download-icon {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0px;
}

.custom-opacity {
  opacity: 0.2;
  cursor: default;
}

.spinner-center {
  display: flex;
  justify-content: center;
}

.btn-gray.btn-secondary.disabled i {
  opacity: 0.3;
}

.track-audio .btn {
  margin-bottom: 10px;
}

.alternate-files {
  display: flex;
}

.alternate-files .alternate-ver {
  margin: 0 5px;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  border-radius: 4px;
}

.alternate-files .alternate-ver.yes {
  background: #13a324;
}

.alternate-files .alternate-ver.no {
  background: #b2b2b2;
}

.alternate-files .alternate-ver.inProcess {
  background: orange;
}

.react-select__menu-list::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.tab-content .icon-btn-filter.vocals .item-container .item .multi-selection {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.multi-selection .react-select__indicator {
  padding: 0px !important;
  color: #333333;
}

.tags-count-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.menu-list-option {
  display: table;
  margin: 4px auto;
  padding: 0 10px;
}

.menu-list-option .option {
  margin: 0;
}

.menu-list-option .option>div:after {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: url(assets/img/exit.svg) no-repeat;
  transform: rotate(45deg);
  margin-left: 5px;
}

.more-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ea4b84;
  font-size: 12px;
  font-weight: 700;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: linear-gradient(228.59deg,
      rgba(230, 186, 12, 0.1) 0%,
      rgba(228, 80, 179, 0.1) 100%);
  margin-left: 4px;
}

.category-checkbox .item .check_lbl .text.top-align,
.text.top-align {
  align-items: flex-start;
  transform: translateY(-10px);
}

.btn-song-cnt {
  font-size: 12px !important;
  padding: 0 !important;
  width: 28px;
  height: 28px;
  border-radius: 100% !important;
  background: #2f353a !important;
}

.child-song {
  background: #555;
  color: #fff;
}

/* .page-loader{
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
} */
.ReactTags__suggestions {
  background: #EEE;
}

.ReactTags__suggestions ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ReactTags__suggestions ul li {
  padding: 5px 10px;
}

.ReactTags__activeSuggestion {
  background: #DDD;
}
.inner-container{
  margin-top: 128px;
}
@media (min-width: 1200px) and (max-width: 1365px) {
  .custon-select-control .css-yk16xz-control {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .main .container-fluid {
    padding: 0 15px !important;
  }

  .drop-zon {
    width: 100%;
  }

  .tab-pad-mobile .nav-tabs .nav-link {
    padding: 0.4rem 0.3rem;
  }

  .rdt_TableFooter {
    white-space: nowrap;
  }

  .category-checkbox .item .check_lbl {
    padding: 0 5px;
    font-size: 13px;
  }

  .category-radio-btn .item {
    flex-basis: calc(100% / 1);
  }

  .category-checkbox .item {
    flex-basis: calc(100% / 2);
  }
}

@media (min-width: 576px) {
  .modal-movie-player.modal-dialog {
    max-width: 400px;
  }
}

.font-12 {
  font-size: 12px;
}

.font-22 {
  font-size: 22px;
}

.disabled-parent .smooth-dnd-container .smooth-dnd-draggable-wrapper {
  pointer-events: none;
}

.react-tags__suggestions {
  z-index: 1001;
}


.dashboard-header {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 4.75px 0.25px rgba(0, 1, 1, 0.1);
  height: 80px;
  padding: 12px 30px;
  position: fixed;
  z-index: 9;
  width: 100%;
}

.date-range-container {
  position: absolute;
  top: 50px;
  z-index: 99;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.top-nav {
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 30px;
}

.top-nav li {
  padding: 0 15px;
}

.device-information {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.device-information .form-control,
.device-information .form-control:hover {
  background-color: #FFF;
  box-shadow: 0px 0px 5px 0px rgba(0, 1, 1, 0.2) !important;
  width: 144px;
  height: 40px;
  outline: 0 !important;
  border: none;
  border-radius: 0;
}

.dashboard-card {
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 1.96px 0.04px rgba(0, 0, 1, 0.02);
}

.dashboard-card .inner-header {
  padding: 20px 25px;
  border-bottom: 3px solid #f2f3f9;
  align-items: center;
}

.dashboard-card .inner-header .title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.expt-btn{ 
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #23282c;
  margin: 0 15px 0 10px;
  text-decoration: none !important;
}
.expt-btn img{
  margin: 0 5px 0 0;
}
.dashboard-card .inner-body {
  padding: 20px 25px;
}

.inner-card {
  border: 1px solid #f8f8f8;
  height: 230px;
  display: flex;
  padding: 0 34px;
  justify-content: center;
}

.inner-card .title {
  font-size: 38px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
}

.inner-card .value {
  font-size: 58px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
}

.inner-card .value.current {
  color: #faa330;
}

.inner-card .value.last {
  color: #808080;
}

.growth-value {
  font-size: 48px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
  margin: 0 0 0 20px;
  white-space: nowrap;
}

.rating-container {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
}

.rating-container .item-container {
  flex: 1;
  padding: 0 15px;
}

.rating-container .item-container.v-bad .item {
  border-bottom: 3px solid #dc3327;
}

.rating-container .item-container.bad .item {
  border-bottom: 3px solid #f16030;
}

.rating-container .item-container.mid .item {
  border-bottom: 3px solid #f6a11e;
}

.rating-container .item-container.good .item {
  border-bottom: 3px solid #eedc12;
}

.rating-container .item-container.v-good .item {
  border-bottom: 3px solid #7fc242;
}

.rating-container .item-container .item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
  border: 1px solid #f8f8f8;
  border-radius: 6px;
}

.rating-container .item-container .item .count-detail {
  margin-left: 15px;
}

.rating-container .item-container .item .count {
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
}

.rating-container .item-container .item .percentage {
  font-size: 18px;
  line-height: 1;
  color: #808080;
  margin-bottom: 0px;
}

.dashboard-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  background: #000000;
  color: #FFF;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.satisfaction-ul {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 40px;
}

.satisfaction-ul li {
  font-size: 13px;
  color: #adb5bd;
  margin-left: 40px;
}

.satisfaction-ul li span {
  display: inline-flex;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  margin-right: 3px;
}

.satisfaction-ul li span.improvement {
  background: #f9bd61;
}

.satisfaction-ul li span.okay {
  background: #eddc12;
}

.satisfaction-ul li span.good {
  background: #7fc342;
}

.satisfaction-lbl {
  font-size: 18px;
  text-align: center;
}

.satisfaction-lbl.improvement {
  color: #f9bd61;
}

.satisfaction-lbl.okay {
  color: #eddc12;
}

.satisfaction-lbl.good {
  color: #7fc342;
}

.warning-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.warning-ul li {
  font-weight: 400;
  padding: 10px 30px;
  border-bottom: 1px solid #f2f3fa;
  display: flex;
  align-items: center;
  min-height: 62px;
}

.warning-ul li:last-child {
  border-bottom: none;
}

.warning-ul li p {
  margin: 0;
}

.warning-ul li strong {
  font-weight: 500;
}

.warning-ul li .index-no {
  color: #808080;
  margin-right: 20px;
}

.fraud-cnt {
  background: #f05252;
  color: #FFF;
  font-weight: 600;
  padding: 4px 10px;
  border-radius: 6px;
  margin: 0 8px;
}

.btn.btn-green-gradient {
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-weight: 500 !important;
  line-height: 1.571;
  border-radius: 5px !important;
  background: -moz-linear-gradient(-7deg, rgb(71, 182, 75) 0%, rgb(130, 197, 103) 100%);
  background: -webkit-linear-gradient(-7deg, rgb(71, 182, 75) 0%, rgb(130, 197, 103) 100%);
}

.table-report tr td,
.table-report tr th {
  border: none !important;
  vertical-align: middle;
}

.table-report {
  color: #808080 !important;
  font-size: 14px;
}

.table-report tr th {
  color: #000;
  font-weight: normal !important;
}

.dashboard-type .form-check-label {
  margin-right: 30px;
}

.min-h350 {
  min-height: 350px;
}

#root {
  position: relative;
  padding-bottom: 60px;
  min-height: 100vh;
}

.device-table {
  position: relative;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 10px;
}

.device-table .rdrCalendarWrapper {
  position: absolute;
  top: 50px;
  left: 0px;
}
.device-table .device-table-main {
  height: 350px;
  overflow-y: auto;
}

.device-table .btn-yellow {
  position: absolute;
  top: 6px;
  right: 0;
}

.flex-1 {
  flex: 1;
}

.acn-link {
  margin: 0 15px 0 10px;
}

.acn-link:hover {
  color: #167495;
  cursor: pointer;
}

@media screen and (max-width: 1024px) and (min-width: 992px) {

  .inner-card .title,
  .growth-value,
  .inner-card .value {
    font-size: 30px;
  }

  .acn-link {
    font-size: 0;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .rating-container {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .rating-container .item-container {
    margin-top: 10px;
  }

  .acn-link {
    font-size: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .container {
    max-width: 100% !important;
    width: 100%;
  }

.form-control.month-select .css-1pahdxg-control,.form-control.month-select .css-yk16xz-control{
  min-height: 34px !important; 
}
  .satisfaction-ul {
    margin-bottom: 10px;
  }

  .rating-container {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .rating-container .item-container {
    margin-top: 10px;
  }

  .rating-container .item-container .item {
    height: 85px;
  }

  .rating-container .item-container .item .count {
    font-size: 20px;
  }

  .rating-container .item-container .item .percentage {
    font-size: 14px;
  }

  .dashboard-card .inner-body,
  .dashboard-card .inner-header {
    padding: 15px;
  }

  .dashboard-card .inner-card {
    padding: 15px;
    height: 100px;
  }

  .dashboard-card .inner-card .title {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  .dashboard-card .inner-card .value {
    font-size: 30px;
    margin-bottom: 0;
  }

  .growth-value {
    font-size: 30px;
  }

  .growth-image {
    width: 80px;
  }

  .dashboard-header {
    padding: 12px 15px;
    height: auto;
  }

  .dashboard-card.mt-5 {
    margin-top: 1rem !important;
  }

  .device-information {
    margin-top: 5px;
  }

  .logo-image {
    width: 40px;
  }

  

  .acn-link {
    margin: 0 0 0 8px;
    font-size: 0;
    position: absolute;
    top: calc(-100% - -5px);
    right: 50px;
  }

  .acn-link img {
    width: 20px;
  }

  .acn-link+.acn-link {
    right: 15px;
  }

  .top-nav {
    margin-left: 0;
  }

  .bar-container canvas {
    height: 400px !important;
  }
}

.form-control.month-select {
  box-shadow: 0px 0px 5px 0px rgba(0, 1, 1, 0.2) !important;
  padding: 0;
  border: 0;
}

.form-control.month-select .css-1uccc91-singleValue {
  color: #5c6873;
}

.form-control.month-select .css-1okebmr-indicatorSeparator {
  display: none;
}

.form-control.month-select .css-tlfecz-indicatorContainer {
  color: #5c6873;
}

.form-control.month-select .css-6q0nyr-Svg {
  width: 17px;
}

.form-control.month-select .css-tlfecz-indicatorContainer {
  padding: 7px 1px 7px 0 !important;
}

.form-control.month-select .css-1pahdxg-control:hover {
  border: 0;
}

.form-control.month-select .css-1pahdxg-control:hover {
  border-color: transparent;
}

.form-control.month-select .css-1pahdxg-control {
  border: 0;
  box-shadow: none;
  border-radius: none;
}

.form-control.month-select .css-1gtu0rj-indicatorContainer {
  padding: 0 1px 0 0;
}

.device-information .cs-from {
  padding: 0 6px !important
}

.dashboard-card {
  position: relative;
}

.dashboard-card .page-loader {
  position: absolute;
}

.form-control.device-control,.form-control.device-control:hover{
  width: 180px;
}


.tol-revision{
  font-size: 16px;
}

.data-text{
  font-size: 20px;
  font-weight: 600;
}
